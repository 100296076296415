import { Injectable } from '@angular/core';
import { Roles } from '../Models/Role';
import { BehaviorSubject, Observable, of,tap } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
    private _baseUrl:string;
    private _roles: BehaviorSubject<Roles[] | null> = new BehaviorSubject(null);

  constructor( private _httpClient:HttpClient) {
    this._baseUrl = `${environment.apiURL}`;
  }

  get roles$():Observable<Roles[]>{
    return this._roles.asObservable();
  }

  public getRoles(){
    return this._httpClient.get<any>
    (`${this._baseUrl}/role/list`)
    .pipe(tap((res) => {
        this._roles.next(res);
    }));
  }

  public getRoleByUserId(){
    return this._httpClient.get<any>(`${this._baseUrl}/navi/user/roles`);
  }


  public changeCurrentRole(roleId:number){
    return this._httpClient.put<any>(`${this._baseUrl}/navi/user/roles/change`,{
        role_new: roleId
    });
  }
}
