<div class="flex">
	<div class="w-10">
        <mat-icon svgIcon="heroicons_solid:information-circle"  class="text-primary"></mat-icon>
    </div>

	<div class="px-4">
		<div class="mb-2">
			<h3 class="font-bold">{{ data.title }}</h3>
		</div>

		<div>
			<p class="font-normal">{{ data.message }}</p>
		</div>

		<div class="flex justify-center mt-5 ">
			<button class="bg-primary ml-3 mt-1 rounded-[12px] border text-white" mat-flat-button (click)="close()">
				Ok
			</button>
		</div>
	</div>
</div>
