import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private baseUrl: string;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
        this.baseUrl = `${environment.apiURL}/common`;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    set userPolicy(newUserValue:User){
        this._user.next(newUserValue);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User>
    {
        this.userDetails().subscribe({next: (user) => {
            localStorage.setItem('filterRole',JSON.stringify(user));
        }});
        return this._httpClient.get<User>(this.baseUrl + '/user').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this._httpClient.put<User>(this.baseUrl + '/user/profile-information', user).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
     password(credentials: { current_password: string; new_password: string; new_password_confirmation: string, policies:number }): Observable<any>
     {
         return this._httpClient.put(environment.apiURL + `/profile/update_password`, credentials);

     }

     sendRequest(user){
        return this._httpClient.post(`${environment.apiURL}/profile/send_request`,user);
     }
//update_avatar/{id}
     updateAvatar(form:FormData){
        return this._httpClient.post(`${environment.apiURL}/profile/update_avatar`,form);
     }

     userDetails(){
        return this._httpClient.get(`${environment.apiURL}/profile/information`);
     }
}
