import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ApplicationRef, Inject, enableProdMode } from '@angular/core';
import { SettingsService } from 'app/modules/admin/settings/services/settings.service';

enableProdMode();

@Component({
  selector: 'app-modal-preview-certificate',
  templateUrl: './modal-preview-certificate.component.html',
  styleUrls: ['./modal-preview-certificate.component.scss']
})
export class ModalPreviewCertificateComponent implements OnInit {

  public app: ApplicationRef;
  public signatures: any[] = [];
  public title: string = '';
  public valueCertificate: string = '';
  public loader: boolean = false;
  public logo:string = "";

  public setting: any = {};
  public certificate: any = {};
  constructor(
    app:ApplicationRef,
    public dialogRef: MatDialogRef<ModalPreviewCertificateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private settingService: SettingsService
  ) {
    this.app = app;
  }

  ngOnInit(): void {
    this.title = this.data.certificate;
    this.valueCertificate = this.data.valueCertificate;
   // this.getSettings();
    this.getCertificate();
    this.settingService.getLogo()
        .subscribe({
            next:(configurationResponse)=>{
                this.logo =  configurationResponse.url_logo;
            }
        });
  }

  getCertificate(): void {
    const ids = this.data.signatures.map(objeto => objeto.id).join(',');
    this.settingService.getDateCetificate(this.title,ids)
    .subscribe(res => {
      this.certificate= res;
      setTimeout(() => {
        this.loader = true;
    }, 800)
    });

  }
  getSettings(): void {
    this.settingService.getDataConfiguration()
    .subscribe(res => {
        this.setting = res;
        this.settingService.getSignatures()
        .subscribe((signatures: any[]) => {

            this.data.signatures.forEach((item: any) => {
                signatures.forEach(sig => {
                    if(sig.id === item.id){
                        this.signatures.push(sig);
                    }
                });
            });

            setTimeout(() => {
                this.loader = true;
            }, 500)
        })
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
