import { Observable } from 'rxjs';
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-trailing-spaces */
import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ModalInfoService } from 'app/modules/category/modalInfo/modal-info.service';

export interface Alert {
	type: string;
	title: string;
	message: string;
}

@Component({
	selector: 'app-modal-alert',
	templateUrl: './modal-alert.component.html',
	styleUrls: ['./modal-alert.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAlertComponent implements OnInit, AfterViewInit {

    public data2: Alert;
    public alertObs: Observable<any>;

	constructor(
		public dialogRef: MatDialogRef<ModalAlertComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Alert,
        private changeRef: ChangeDetectorRef,
        private modalService: ModalInfoService
	) {
    }

	ngOnInit(): void {

    }

	ngAfterViewInit(): void {
        setTimeout(() => {
            this.changeRef.markForCheck();
            this.alertObs = null;
        }, 0);
	}

	close(): void {

        this.changeRef.detectChanges();

        setTimeout(() => {
            this.dialogRef.close();
        }, 0);

	}
}
