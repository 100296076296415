<div class="flex">
	<div class="w-10">
		<mat-icon svgIcon="mat_solid:delete"></mat-icon>
	</div>

	<div class="px-4">
		<div class="mb-2">
			<h3 class="font-bold">Eliminar</h3>
		</div>

		<div>
			<p class="font-normal">Está seguro que desea eliminar este objeto permanentemente?
                Esta acción no puede volver a deshacerse
			</p>
		</div>


        <div *ngIf="(data$ | async) as usersList">
            <span>
                {{ usersList | json }}
            </span>
        </div>

		<div class="flex justify-end mt-5">
			<button class="ml-3 mt-1 rounded-[12px] border" mat-flat-button [color]="'light'" (click)="cerrar()">
				Cancelar
			</button>

			<button class="ml-3 mt-1 rounded-[12px]" mat-flat-button [color]="'primary'"
          (click)="confirm()">
				Eliminar
			</button>
		</div>
	</div>
</div>
