import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { environment } from 'environments/environment';
import { UserService } from 'app/core/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService
{
    private baseUrl: string;
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private _userService:UserService)
    {
        this.baseUrl = `${environment.apiURL}`;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<any[]>
    {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(id,role_id): Observable<any[]>
    {
        return this._httpClient.get<any[]>(`${this.baseUrl}/events/notifications`).pipe(
            tap((notifications) => {



                this._notifications.next(notifications);

            })
        );
    }

    updateNotificationStatus(id): Observable<any> {
        return this._httpClient.put<any>(`${this.baseUrl}/events/notification/read`,{
            notification:id,
        });
    }

    /**
     *
     * @param id notification id
     * @returns
     */
    deleteNotification(id){
        return this._httpClient.delete<any>(`${this.baseUrl}/events/notification/delete`,{
            params:{
                notification:id,
            }
        });
    }

    updateAllNotificationStatus(id): Observable<any> {
        return this._httpClient.put<any>(`${this.baseUrl}/events/notification/read-all`,{});
    }
}
