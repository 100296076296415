import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, Subject, tap, BehaviorSubject } from 'rxjs';
import { GlobalSettings } from '../Models/GlobalSettings';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    private _settingsSubject: Subject<GlobalSettings> = new Subject<GlobalSettings>();
    private _settings: ReplaySubject<GlobalSettings> = new ReplaySubject<GlobalSettings>(1);
    private _baseUrl: string;
    private _signatures: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _basicSettings: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _certificate: BehaviorSubject<any | null> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient) {
        this._baseUrl = `${environment.apiURL}`;
    }

    get basicSettings$() {
        return this._basicSettings.asObservable();
    }

    get signatures$() {
        return this._signatures.asObservable();
    }

    get settingsSubject$(): Observable<GlobalSettings> {
        return this._settingsSubject.asObservable();
    }

    get settings$(): Observable<GlobalSettings> {
        return this._settings.asObservable();
    }

    public getLogo():Observable<any> {
        return this._httpClient.get(`${this._baseUrl}/template_login/get`).pipe(
            tap((set) => {
                this._basicSettings.next(set);
            })
        );
    }

    //send type: logo | banner | signature
    sendImage(image: FormData) {
        return this._httpClient.post(`${this._baseUrl}/configurations/updates`, image);
    }

    getDataConfiguration() {
        return this._httpClient.get(`${this._baseUrl}/configurations/list`).pipe(
            tap((set) => {
                this._settings.next(set);
            })
        );
    }

    public getSignatures() {
        return this._httpClient.get(`${this._baseUrl}/signatures/list`).pipe(
            tap((set) => {
                this._signatures.next(set);
            })
        );
    }
    public getDateCetificate(name:String, signature:any) {
        return this._httpClient.get(`${this._baseUrl}/certificate/preview?title=`+name+`&signatures=`+signature).pipe(
            tap((set) => {
                this._certificate.next(set);
            })
        );
    }
    public updateSignature(data: FormData) {
        return this._httpClient.post(`${this._baseUrl}/signatures/update`, data);
    }


    public createSignature(data) {

    }

    updateConfiguration(data: FormData) {
        return this._httpClient.post(`${this._baseUrl}/configurations/updates`, data);
    }

    deleteSignature(image: FormData) {
        return this._httpClient.post(`${this._baseUrl}/signatures/update`, image);
    }

    sendEmailForm(emailForm: FormData) {
        return this._httpClient.post(`${this._baseUrl}/configurations/updates`, emailForm);
    }

    saveColors(color: FormData) {
        return this._httpClient.post(`${this._baseUrl}/configurations/updates`, color);
    }
    saveName(name:String){
        return this._httpClient.post(`${this._baseUrl}/configurations/updates`,name);
    }
}
