import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor(private readonly swUpdate: SwUpdate)
    {
        this.actualizarApp();
    }
    actualizarApp(){
        if (this.swUpdate.available){
            this.swUpdate.available.subscribe(()=>{
                if(confirm('Nueva versión disponible.\n Al aceptar, se recargará la plataforma, lo que podría causar la pérdida de trabajo no guardado.\n Sin embargo, esta actualización es crucial para mejorar tu experiencia de usuario.')){
                    window.location.reload();
                }
            });
        }
        }
}
