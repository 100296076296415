import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import Echo from 'laravel-echo';
import moment from 'moment';
import { environment } from 'environments/environment';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Router } from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
declare var require: any;
//comment
@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications = [];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    echoInstance: any;
    user: User;
    newPostText = 'Nueva publicación';
    newReplyText = 'Nueva respuesta';
    newReactionText = 'Nueva reacción';

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _userService: UserService,
        private router: Router,
        private _navigationService: NavigationService,
    ) {
        //subscription to channel.
        try {
            // laravel-echo requires global Pusher instance
            (<any>window).Pusher = require("pusher-js");
            /* Registering to broadcasting events */
            this.echoInstance = new Echo({
                broadcaster: "pusher",
                key: environment.echoKey,
                wsHost: "realtime-pusher.ably.io",
                wsPort: 443,
                disableStats: true,
                encrypted: true
            });
        } catch (e) {
            console.log(e);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                this._notificationsService.getAll(user.id,user.role_id)
                    // Subscribe to notification changes
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((notifications: Notification[]) => {
                        if(Number(user.role_id)==2){
                            // Load the notifications
                            this.notifications = this.formatNotifications(notifications.filter((notif:any)=>notif.channel == "academy.events"));
                        }else{
                            // Load the notifications
                            this.notifications = this.formatNotifications(notifications.filter((notif:any)=>notif.channel == "campus.events"));
                        }

                        // Load the notifications
                        //this.notifications = this.formatNotifications(notifications);

                        // Calculate the unread count

                        // Mark for check
                        this._changeDetectorRef.markForCheck();
                        this._calculateUnreadCount();
                    });
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });


        //TMP CODE HERE
        // this.notifications = [{
        //     id         : 'post' + 1,
        //     uuid       : 1,
        //     post_id    : 2,
        //     title      : "Notificacion 1",
        //     description:"Evento not 1",
        //             // time       : event.post.created,
        //     time       : moment().locale('es').fromNow(),
        //     current_time : moment(new Date()),
        //     read       : false
        // }];
        // Calculate the unread count

    }

    private _buildNotification(event,user){


        if (event.notification_history.id_user == user.id) {


            let notification = {
                id_user: event.notification_history.id_user,
                id_notif: event.notification_history.id_notif,
                uuid: event.notification_history.uuid,
                description: event.notification_history.data,
                //data: event.data,
                //description: event.post.name,
                // time       : event.post.created,
                time: moment().locale('es').fromNow(),
                //current_time : moment(event.post.created_at),
                read: false,
                title: ""
            }

            // switch (event.notification_history.event_name) {
            //     case 'new.instructive':
            //         notification.title = "Se ha agregado un nuevo instructivo en biblioteca.";
            //         break;
            //     case 'new.course.assigned':
            //         notification.title = "Se te ha asignado el curso";
            //         break;
            //     case 'course.today':
            //         notification.title = "Hoy inicia el curso";
            //         break;
            //     case 'exam.today':
            //         notification.title = "Hoy tienes un examen";
            //         break;
            //     case 'exam.tomorrow':
            //         notification.title = "Mañana tienes un examen";
            //         break;

            //     case 'new.requestcp':
            //         notification.title = "Tienes una solicitud de un plan carrera. Solicitado por";
            //         break;
            // }


            switch (event.notification_history.event_name) {
                case 'new.instructive':
                    notification.title = "Se ha agregado el instructivo <strong>"+event.notification_history.data +" </strong> en biblioteca.";
                    break;
                case 'new.course.assigned':
                    notification.title = "Se te ha asignado el curso <strong>" + event.notification_history.data+ "</strong>";
                    break;
                case 'course.today':
                    notification.title = "El curso <strong>"+event.notification_history.data+" </strong>inicia el día de hoy";
                    break;
                case 'exam.today':
                    notification.title = "El examen teórico del curso<strong> <strong>"+event.notification_history.data+" </strong>inicia el día de hoy";
                    break;
                case 'exam.tomorrow':
                    notification.title = "El examen teórico del curso <strong>"+event.notification_history.data+" </strong>inicia el día de mañana";
                    break;

                case 'new.requestcp':
                    notification.title = "El estudiante <strong>"+event.notification_history.data+"</strong> ha solicitado un plan carrera";
                    break;

                case 'practical.active':
                    notification.title = "El exámen práctico del ciclo <strong>"+event.notification_history.data+"</strong> ha sido activado";
                    break;
            }

            this.notifications.unshift(notification);
            // "event" receives the new post information
            // Calculate the unread count
            this._calculateUnreadCount();

            // Mark for check
            this._changeDetectorRef.markForCheck();
        }
    }

    ngAfterViewInit(): void {
        /* Listening events */
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                if (Number(user.role_id) == 2) {
                    this.echoInstance
                        //channel to change from backend. aqui cambiar
                        .channel("academy.events")
                        .subscribed(() => {
                           // console.log("You are subscribed");
                        })
                        ///backend event defined on listen (event,function)
                        .listen(".new.instructive", (event) => {
                            //structure of data received

                            this._buildNotification(event,user);
                        })
                        .listen(".new.course.assigned", (event) => {
                            this._buildNotification(event,user);
                        })
                        .listen(".course.today", (event) => {
                            this._buildNotification(event,user);
                        })
                        .listen(".exam.today", (event) => {
                            this._buildNotification(event,user);
                        })
                        .listen(".exam.tomorrow", (event) => {
                            this._buildNotification(event,user);
                        })
                        .listen(".practical.active", (event) => {
                            this._buildNotification(event,user);
                        })
                }else{
                    this.echoInstance
                        //channel to change from backend. aqui cambiar
                        .channel("campus.events")
                        .subscribed(() => {
                            //console.log("You are subscribed");
                        })
                        ///backend event defined on listen (event,function)
                        .listen(".new.requestcp", (event) => {
                            //structure of data received
                            this._buildNotification(event,user);
                        })

                }

            });


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        this.calculateCurrentElapsedTimeForNotifications();
        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        if (this.unreadCount > 0) {

            this.notifications.forEach(notif => {
                notif.read = true;
            });
            this._calculateUnreadCount();
            this._changeDetectorRef.detectChanges();
            this._userService.user$
            .subscribe({
                next:(user)=>{
                    this._notificationsService.updateAllNotificationStatus(user.id).subscribe();
                }
            })
            // Mark all as read

        }
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: any): void {
        // Toggle the read status
        notification.read = true;
        this._calculateUnreadCount();
        this._changeDetectorRef.detectChanges();

        // Update the notification

        this._notificationsService.updateNotificationStatus(notification.id).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: any): void {
        let idx = this.notifications.indexOf(notification);
        if (idx >= 0) {
            this.notifications.splice(idx, 1);
            this._calculateUnreadCount();
            this._changeDetectorRef.detectChanges();

            // Delete the notification
            this._notificationsService.deleteNotification(notification.id).subscribe();
        }
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(notification => !notification.read).length;
        }

        this.unreadCount = count;

    }

    openNotification(notification) {

        //get home redireciton
        this._navigationService.navigation$.subscribe({
            next: (value) => {
                this._userService.user$.subscribe({
                    next: (userVal) => {
                        let id = userVal.role_id;
                        if (Number(id) != 2) {
                            //Workaround to reload component each time
                            this.router.navigateByUrl('/apps/plan/request', { skipLocationChange: true }).then(() => {
                                //this.router.navigate(['/'], { queryParams: { c_id: notification.comment_id } });
                                this.router.navigate(['/apps/plan/request']);
                            });

                        } else {
                            switch(notification.event){
                                case 'new.instructive':
                                    this.router.navigateByUrl('/apps/overview/categories', { skipLocationChange: true }).then(() => {
                                        //this.router.navigate(['/'], { queryParams: { c_id: notification.comment_id } });
                                        this.router.navigate(['/apps/overview/categories']);
                                    });
                                    break;
                                case 'new.course.assigned':
                                    this.router.navigateByUrl('/apps/overview/cours', { skipLocationChange: true }).then(() => {
                                        //this.router.navigate(['/'], { queryParams: { c_id: notification.comment_id } });
                                        this.router.navigate(['/apps/overview/cours']);
                                    });
                                    break;

                                case 'course.today':
                                    this.router.navigateByUrl('/apps/overview/cours', { skipLocationChange: true }).then(() => {
                                        //this.router.navigate(['/'], { queryParams: { c_id: notification.comment_id } });
                                        this.router.navigate(['/apps/overview/cours']);
                                    });
                                    break;

                                case 'exam.today':
                                    this.router.navigateByUrl('/apps/overview/cours', { skipLocationChange: true }).then(() => {
                                        //this.router.navigate(['/'], { queryParams: { c_id: notification.comment_id } });
                                        this.router.navigate(['/apps/overview/cours']);
                                    });
                                    break;
                                case 'exam.tomorrow':
                                        this.router.navigateByUrl('/apps/overview/cours', { skipLocationChange: true }).then(() => {
                                            //this.router.navigate(['/'], { queryParams: { c_id: notification.comment_id } });
                                            this.router.navigate(['/apps/overview/cours']);
                                        });
                                        break;
                            }

                            // this.router.navigateByUrl('/apps/overview/cours', { skipLocationChange: true }).then(() => {
                            //     //this.router.navigate(['/'], { queryParams: { c_id: notification.comment_id } });
                            //     this.router.navigate(['/apps/overview/cours']);
                            // });
                        }
                        //Update read status if status has not been updated previously
                        !notification.read ? this.toggleRead(notification) : null;
                        this.closePanel();
                    }
                });

            }
        });
        // const url = this.router.serializeUrl(this.router.createUrlTree(['/apps/userhome/feed/' + notification.id]));
        // window.open(url, '_blank');
    }

    calculateCurrentElapsedTimeForNotifications() {
        this.notifications.forEach(item => {
            item.time = moment(item.current_time).locale('es').fromNow();
        });
        this.notifications.sort((a, b) => moment(b.current_time).diff(moment(a.current_time)));
    }

    formatNotifications(notifications) {
        let formattedNotifications = [];




        //Loop through all notifications
        notifications.forEach(notif => {
            let notification = {
                id: notif.id,
                uuid: notif.uuid,
                title: "",
                event:notif.event,
                //data: notif.data,
                description: "",
                // time       : event.post.created,
                time: moment(notif.time).locale('es').fromNow(),
                current_time : notif.time,
                read: notif.action == 'Read' ? true : false
            }


            //     new.instructive
            // new.course.assigned
            // course.today
            // exam.today
            // exam.tomorrow


            switch (notif.event) {
                case 'new.instructive':
                    notification.title = "Se ha agregado el instructivo <strong>"+notif.content +" </strong> en biblioteca.";
                    break;
                case 'new.course.assigned':
                    notification.title = "Se te ha asignado el curso <strong>" + notif.content+ "</strong>";
                    break;
                case 'course.today':
                    notification.title = "El curso <strong>"+notif.content+" </strong>inicia el día de hoy";
                    break;
                case 'exam.today':
                    notification.title = "El examen teórico del curso<strong> <strong>"+notif.content+" </strong>inicia el día de hoy";
                    break;
                case 'exam.tomorrow':
                    notification.title = "El examen teórico del curso <strong>"+notif.content+" </strong>inicia el día de mañana";
                    break;

                case 'new.requestcp':
                    notification.title = "El estudiante <strong>"+notif.content+"</strong> ha solicitado un plan carrera";
                    break;
                case 'practical.active':
                    notification.title = "El exámen práctico del ciclo <strong>"+notif.content+"</strong> ha sido activado";
                    break;
            }

            //notification.description = notif.content;
            formattedNotifications.push(notification);

        });
        return formattedNotifications;
    }

}
