import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CrumbsComponent } from './crumbs/crumbs.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { FuseAlertModule } from '@fuse/components/alert';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ModalSuccessComponent } from './modal-success/modal-success.component';
import { ProgressComponent } from './progress/progress.component';
import { ModalPreviewCertificateComponent } from './modal-preview-certificate/modal-preview-certificate.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FuseAlertModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CrumbsComponent,
        ModalConfirmComponent,
        ModalAlertComponent,
        ProgressComponent,
        ModalPreviewCertificateComponent
    ],
    declarations: [
      CrumbsComponent,
      ModalConfirmComponent,
      ModalAlertComponent,
      ModalErrorComponent,
      ModalSuccessComponent,
      ProgressComponent,
      ModalPreviewCertificateComponent
    ]
})
export class SharedModule
{
}
