import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { AlertModal } from 'app/core/module/alert.type';
import { ModalInfoService } from 'app/modules/category/modalInfo/modal-info.service';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data$: Observable<AlertModal>,
    private modalInfo: ModalInfoService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  cerrar(){
    this.dialogRef.close(false);
  }

  confirm(){
    this.dialogRef.close(true);
  }
}
