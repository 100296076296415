<mat-stroked-button color="primary" class="block text-[12px] w-[80px] sm:w-full hover:cursor-pointer border-2 border-primary rounded-lg p-2 text-primary"
    mat-icon-button [matMenuTriggerFor]="changeMenuAction">
        <span *ngIf="detectRol != 2">Estás en Campus </span>
        <span *ngIf="detectRol == 2 ">Estás en Academia </span>
</mat-stroked-button>

<ng-container *ngIf="(rolesOptions$ | async) as roles">
    <ng-container *ngFor="let rol of roles; index as i">
            <ng-container *ngIf="rol.id != user.role_id">
                <mat-menu class="menuPrincipal"
                [xPosition]="'before'"
                #changeMenuAction="matMenu">
                            <button
                            *ngIf="user.policies != 0"
                            (click)="changeRol(user.id,rol.id)"
                            mat-menu-item class="flex items-center">
                                <span *ngIf="rol.id == 2">Ir a Academia
                                    <mat-icon svgIcon="heroicons_outline:logout"></mat-icon>
                                </span>
                                <span *ngIf="rol.id != 2"> Ir a Campus
                                    <mat-icon svgIcon="heroicons_outline:logout"></mat-icon>
                                </span>
                            </button>
                </mat-menu>
            </ng-container>
    </ng-container>
</ng-container>
