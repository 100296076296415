/* eslint-disable no-trailing-spaces */
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Alert } from '../modal-alert/modal-alert.component';

@Component({
  selector: 'app-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss']
})
export class ModalSuccessComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ModalSuccessComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Alert,
        private changeRef: ChangeDetectorRef
      ) { }

      ngOnInit(): void {
      }

      close(): void {
            this.changeRef.detectChanges();

            setTimeout(() => {
                this.dialogRef.close();
            }, 0);

        }
}
