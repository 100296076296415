import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { users } from './data';
import { cloneDeep } from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class UsersMockApi
{
    private _users:any[];
    /**
     * Constructor
     */
     constructor(private _fuseMockApiService: FuseMockApiService)
     {
         // Set the data
         this._users = users;

         // Register Mock API handlers
         this.registerHandlers();
     }

    /**
    * Register Mock API handlers
    */
    registerHandlers(): void
    {
        // users - GET
        this._fuseMockApiService
            .onGet('api/users')
            .reply(() => {

            // Clone the data to preserve the originals
            const compactUsers = cloneDeep(this._users);

            // Do some stuff with your data

            // Return
            return [200, {
                data: compactUsers,
            }];
        })
    }
}
