import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ApplicationRef, Inject, enableProdMode, ChangeDetectorRef, ViewChild } from '@angular/core';
import { SettingsService } from 'app/modules/admin/settings/services/settings.service';
import { UserService } from 'app/core/user/user.service';
import { RoleService } from 'app/modules/admin/user-management/services/role.service';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { MatMenuTrigger } from '@angular/material/menu';

enableProdMode();

@Component({
  selector: 'app-menu-change-rol',
  templateUrl: './menu-change-rol.component.html',
  styleUrls: ['./menu-change-rol.component.scss']
})

export class MenuChangeRolComponent implements OnInit {

    public rolesOptions$:Observable<any> = new Observable();
    user: User;
    public detectRol:any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('changeMenuAction') changeMenuAction: MatMenuTrigger;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _userService: UserService,
        private _roleService:RoleService){}

    ngOnInit(): void {
        this._userService.user$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((user: User) => {
            this.user = user;
            this.detectRol=user.role_id;
            // Mark for check
            this._changeDetectorRef.markForCheck();

        });
          //dont delete both change detectors
          this._changeDetectorRef.markForCheck();
          setTimeout(() => {
              // Mark for check
              this._changeDetectorRef.markForCheck();
              this.rolesOptions$ = this._roleService.getRoleByUserId()
                      .pipe(
                          map(value=>{

                              return value.roles_associated;
                          })
                      );
          }, 0);
    }

    public changeRol(id:number,role_id:number){
        this.detectRol=role_id;
        this._roleService.changeCurrentRole(role_id).subscribe({
            complete:()=>{
                window.location.href = "/";
            }
        });

    }

}
