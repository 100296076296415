import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable,switchMap,of, takeUntil, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationGuard implements CanActivate {
    private _navigator$: Observable<Navigation>;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(private _navigationService:NavigationService,
        private _router: Router){
        this._navigator$ = this._navigationService.get();
    }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{

        const redirectUrl:string = state.url === '/sign-out' ? '/' : state.url;

       return this._navigator$.pipe(
        takeUntil(this._unsubscribeAll),
        switchMap(values=>{
            //comparar primero la longitud de la cadena y verificar si es mayor o igual al de la bd
            //luego comparar ambas cadenas quitando la diferencia de caracteres
            // front-> /apps/users/edit/0   back-> /apps/users/edit
            // front.length>=back.length
            // front.slice(0,back.length-1]
            const menus = values.compact;
            let existe  = false;

            for(let i = 0; i < menus.length; i++){
                if(menus[i].link === null){ // Tiene hijos
                  for(let j = 0; j < menus[i].children.length; j++){
                    if(menus[i].children[j].link === redirectUrl) existe = true;
                  }
                }else{
                    if(menus[i].link === redirectUrl) existe = true;
                }
            }

            if(existe){
                return of(existe);
            }else{
                this._router.navigateByUrl(menus[0].link);
                return of(existe);
            }
            // let index = values.compact.findIndex(item=>item.link == redirectUrl);

            // let arr = values.compact.find(item=>item.children.length>0);
            // let index2 =-1;

            // if(arr){
            //     index2 = arr.children.findIndex(item=>item.link == redirectUrl);
            // }

            // if(index==-1 && index2 ==-1){
            //     this._router.navigateByUrl(`${values.compact[0].link}`);
            //     return of(false);
            // }else{

            //     return of(true);
            // }
        })
       );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._navigator$.pipe(
            switchMap(values=>{
                let index = values.compact.findIndex(item=>item.link == redirectUrl);
                let arr = values.compact.find(item=>item.children.length>0);
                let index2 =-1;



                if(arr){
                    index2 = arr.children.findIndex(item=>item.link == redirectUrl);
                }

                if(index==-1 && index2 ==-1){
                    this._router.navigateByUrl(`${values.compact[0].link}`);
                    return of(false);
                }else{
                    return of(true);
                }
            })
           );
    }

}
