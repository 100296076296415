import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Alert } from './../modal-alert/modal-alert.component';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Alert,
    private changeRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  close(): void {
        this.changeRef.detectChanges();

        setTimeout(() => {
            this.dialogRef.close();
        }, 0);

    }
}
