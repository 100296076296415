import { HttpClient } from '@angular/common/http';
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
import { Observable } from 'rxjs';
/* eslint-disable no-trailing-spaces */
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AlertModal } from 'app/core/module/alert.type';

@Injectable({
	providedIn: 'root'
})
export class ModalInfoService {

    constructor(
        private http: HttpClient
    ){

    }

    public getInfo(): Observable<any>{
        return this.http.get('./../../../../assets/alert/info.json');
    }
}
