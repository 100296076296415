<div>
    <!-- loader -->
    <ng-container *ngIf="!loader; else loading">
        <div class="w-full flex items-center justify-center flex-col">
            <div class="dottet-loader"></div>
            <div class="font-bold mt-4 flex flex-col">
                <span class="font-bold text-center">Espere un momento</span>
                <span class="font-semibold">Estamos generando el certificado</span>
            </div>
        </div>
    </ng-container>

    <ng-template #loading>
        <div class="overflow-y-auto" style="min-width: 600px; min-height: 400px;  max-height: 400px;">
            <div class="w-full flex flex-row" id="certificate"
                [style]="'background-image: url(' + certificate.banner + '); background-size: 98% 98%; background-repeat: no-repeat; height: 400px !important; padding-bottom: 10px'">
                <section class="w-[252px]">
                    <div class="w-full flex pl-4 items-center justify-center">
                        <img
                        style="width:125px; height: 125px; padding-top: 10px;"
                        [src]="logo" class="rounded-full" alt="enterprise_logo">
                    </div>
                </section>
                <div class="w-[348px] flex flex-col justify-end">
                    <div class="w-full flex justify-center">
                        <span class="block text-xl font-bold mb-4" style="width: 80%; text-align: center;">{{title.charAt(0).toUpperCase()+ title.slice(1)}}</span>
                    </div>
                    <div *ngIf="valueCertificate === 'ciclos'">
                        <div class="w-full pb-4 flex flex-col justify-center text-center"
                            style="line-height: 30px;">
                            <span class="block text-sm" style="letter-spacing: 0.12rem;">Otorgado a:</span>
                            <span class="block text-3xl font-bold pb-2 border-b m-2">Andres Ocampo</span>
                            <span class="block text-sm" style="letter-spacing: 0.12rem;">Por haber completado con éxito el ciclo</span>
                        </div>
                    </div>
                    <div class="w-full pb-4 flex flex-col justify-center text-center"
                        style="line-height: 30px;" *ngIf="valueCertificate === 'modulo'">
                        <span class="block text-sm" style="letter-spacing: 0.12rem;">Otorgado a:</span>
                        <span class="block text-xl font-bold pb-2 border-b m-2">Andres Ocampo</span>
                        <span class="block text-sm" style="letter-spacing: 0.12rem;">Por haber completado con éxito el módulo</span>

                    </div>
                    <div class="w-full pb-4 flex flex-col justify-center text-center"
                        style="line-height: 30px;" *ngIf="valueCertificate === 'perfil'">
                        <span class="block text-sm" style="letter-spacing: 0.12rem;">Otorgado a:</span>
                        <span class="block text-3xl font-bold pb-2 border-b m-2">Andres Ocampo</span>
                        <span class="block text-sm" style="letter-spacing: 0.12rem;">Por haber completado con éxito el perfil</span>

                    </div>

                    <div class="w-full pt-3 flex justify-center text-center">
                        <div class="mx-2" *ngFor="let sig of certificate.signatures">
                            <div class="flex flex-col items-center">
                                <img [src]="sig.img" alt="" style="width: 70px;" class="mb-2">
                                <span class="block text-sm">{{ sig.name }}</span>
                                <span class="block text-sm">{{ sig.role }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-end mt-5 "  style="min-width: 600px;">
            <button class="border w-20 rounded-xl p-1 h-9 mr-3"  (click)="close()">Cerrar</button>
        </div>
    </ng-template>
</div>
