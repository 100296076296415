export const users = [
    {
        current_page: 1,
        data: [
            {
                id:1,
                name:"Danny Alexander",
                lastname: "Pacheco Holguin",
                email:"dpac4796@gmail.com",
                role:{
                    id:2,
                    name:"estudiante",
                    display_name:"Estudiante"
                },
                restaurants:{
                    id:1,
                    name:"Cielo Fisher"
                },
                specialties:{
                    id:1,
                    name: "Sanduches Tradicionales"
                }
            }
        ],
        first_page_url: "https://api.laraveltraining.test/api/users/list?query=&page=1",
        from: 1,
        last_page: 5,
        last_page_url: "https://api.laraveltraining.test/api/users/list?query=&page=5",
        links: [
            {
                url: null,
                label: "&laquo; Anterior",
                active: false
            },
            {
                url: "https://api.laraveltraining.test/api/users/list?query=&page=1",
                label: "1",
                active: true
            },
            {
                url: "https://api.laraveltraining.test/api/users/list?query=&page=2",
                label: "2",
                active: false
            },
            {
                url: "https://api.laraveltraining.test/api/users/list?query=&page=3",
                label: "3",
                active: false
            },
            {
                url: "https://api.laraveltraining.test/api/users/list?query=&page=4",
                label: "4",
                active: false
            },
            {
                url: "https://api.laraveltraining.test/api/users/list?query=&page=5",
                label: "5",
                active: false
            },
            {
                url: "https://api.laraveltraining.test/api/users/list?query=&page=2",
                label: "Siguiente &raquo;",
                active: false
            }
        ],
        next_page_url: "https://api.laraveltraining.test/api/users/list?query=&page=2",
        path: "https://api.laraveltraining.test/api/users/list",
        per_page: 15,
        prev_page_url: null,
        to: 14,
        total: 64,
        aliases: {
            id: "Id",
            name: "Nombre completo",
            email: "Correo",
            role_name: "Rol"
        },
        columns_order: [
            "id",
            "name",
            "email",
            "role_name"
        ]

    }

]
